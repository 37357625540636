<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí SEO</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogInfo"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm mục</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Page</th>
                    <th scope="col">Type</th>
                    <th scope="col">item</th>
                    <th scope="col">title</th>
                    <!--                  <th scope="col">keywords</th>-->
                    <th scope="col">slug</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(information, i) in SEO_informations">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ information.page }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ information.type_label }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="Object.keys(information.item).length > 0"
                        >
                          <template v-if="information.type === 2 || information.type === 3 || information.type === 4">
                            {{ information.item.name.vi }}
                          </template>
                          <template v-if="information.type === 5">
                            {{ information.item.name }}
                          </template>
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ information.title }}
                        </p>
                      </td>
                      <td>
                        <!--                      <template v-for="(keyword, i) in information.keywords">-->
                        <!--                        <span class="label label-lg label-inline font-weight-bold py-4 label-light-info ml-2" :key="i">{{keyword}}</span>-->
                        <!--                      </template>-->
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="Object.keys(information.item).length > 0"
                        >
                          {{ information.item.slug }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editInfo(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteInfo(information.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!SEO_informations.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="diaLogInfo" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'">Thêm mục</span>
              <span class="headline" v-else>Sửa mục</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="diaLogInfo = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0">
                      <label class="text-h6">Title:</label>
                    </v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field v-model="title_input" dense filled></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Mô tả:</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-textarea outlined v-model="description_input" auto-grow></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Type:</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="type_input"
                        :items="types"
                        item-value="value"
                        item-text="label"
                        dense filled
                        @click="changeTypeSEO"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 1">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Page:</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-autocomplete
                        v-model="page_input"
                        :items="pages"
                        item-value="value"
                        item-text="label"
                        dense filled
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 2">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Chương trình:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="item_input"
                        :items="programmes"
                        item-value="id"
                        item-text="name.vi"
                        dense filled
                        @change="chooseProgramme"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 3">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Product Category:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="item_input"
                        :items="product_categories"
                        item-value="id"
                        item-text="name.vi"
                        dense filled
                        @change="chooseProductCategory"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 4">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Product Sub Category:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="item_input"
                        :items="product_sub_categories"
                        item-value="id"
                        item-text="name.vi"
                        dense filled
                        @change="chooseProductSubCategory"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="type_input === 5">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Benefit:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="item_input"
                        :items="benefits"
                        item-text="name"
                        item-value="id"
                        dense filled
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if=" type_input === 2 || type_input === 3 || type_input === 4">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Slug:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-text-field
                        v-model="slug_input"
                        dense
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Keywords:</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-card flat style="width: 100%">
                        <v-row>
                          <v-col cols="7" class="p-0">
                            <v-textarea
                              outlined auto-grow dense
                              rows="1"
                              class="ml-4 p-0 mt-4 text-body-1"
                              v-model="item_keyword_input"
                            ></v-textarea>
                          </v-col>
                          <v-btn
                            color="cyan"
                            class="ma-2 mt-4 font-weight-bold"
                            outlined
                            @click="addItemKeyword"
                          >
                            <v-icon small left>mdi-check</v-icon> Lưu
                          </v-btn>
                          <v-btn
                            color="#E53935"
                            class="mt-4 font-weight-bold"
                            outlined
                            @click=" () => {
                                this.item_keyword_input = '';
                                this.index_item_keyword = null;
                              }">
                            <v-icon small left>mdi-close</v-icon> Hủy nhập
                          </v-btn>
                        </v-row>
                        <ul
                          style="background-color: #f3f6f9; border-radius: 5px"
                          class="pt-2 pb-2"
                          v-if="keyword_input.length > 0"
                        >
                          <template v-for="(keyword, i) in keyword_input">
                            <li class="d-flex mt-4" :key="i">
                              <v-btn
                                color="#FFAB00"
                                outlined
                                small
                                @click="editItemKeyword(i)"
                              >
                                <v-icon small
                                  >mdi-file-document-edit-outline</v-icon
                                >
                              </v-btn>
                              <v-btn
                                color="#D84315"
                                class="ml-1"
                                outlined
                                small
                                @click="deleteItemKeyword(i)"
                              >
                                <v-icon small>mdi-close</v-icon>
                              </v-btn>
                              <p
                                class="text-body-1 mb-0 ml-2 mr-2 text-justify"
                              >
                                {{ keyword }}
                              </p>
                            </li>
                          </template>
                        </ul>
                        <div
                          v-if="keyword_input.length <= 0"
                          style="
                            background-color: #f3f6f9;
                            border-radius: 5px;
                            min-height: 30px;
                            padding-top: 5px;
                          "
                        >
                          <p style="text-align: center">No data</p>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditInfo(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditInfo(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="diaLogInfo = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
// import Editor from "@/components/ckeditor5/Editor";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "SEOList",
  components: {
    // Editor,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      SEO_informations: [],
      is_call_api: false,
      diaLogInfo: false,
      programmes: [],
      item_input: null,
      description_input: "",
      type_form: null,
      pages: [],
      page_input: "",
      types: [],
      type_input: null,
      slug_input: "",
      product_categories: [],
      title_input: "",
      keyword_input: [],
      index_item_keyword: null,
      item_keyword_input: "",
      SEO_info_id: null,
      product_sub_categories: [],
      benefits: [],
    };
  },
  watch: {},
  created() {
    this.getAllSEOInfo();
    this.getTypeSEO();
    this.getPageSEO();
    this.getAllProgramme();
    this.getAllProductCategory();
    this.getAllProductSubCategory();
    this.getAllBenefit();
  },
  methods: {
    async getAllProgramme() {
      let seft = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          seft.programmes = response.data;
        }
      });
    },
    async getAllProductCategory() {
      let seft = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          seft.product_categories = response.data;
        }
      });
    },
    async getAllProductSubCategory() {
      let seft = this;
      await ApiService.get(
        "prep-app/product-category/all-slug-sub-category"
      ).then(function (response) {
        if (response.status === 200) {
          seft.product_sub_categories = response.data;
        }
      });
    },
    async getAllBenefit() {
      let vm = this;
      await ApiService.get("prep-app/benefit")
      .then(function (response) {
        if (response.status === 200) {
          vm.benefits = response.data;
        }
      });
    },
    async getAllSEOInfo() {
      let vm = this;
      this.is_call_api = true;
      await ApiService.get("prep-app/seo")
        .then(function (response) {
          if (response.status === 200) {
            vm.SEO_informations = response.data;
          }
          vm.is_call_api = false;
        })
        .catch(function (err) {
          console.log(err);
          vm.is_call_api = false;
        });
    },
    async getTypeSEO() {
      let vm = this;
      await ApiService.get("prep-app/seo/listType").then(function (response) {
        if (response.status === 200) {
          vm.types = response.data;
        }
      });
    },
    async getPageSEO() {
      let vm = this;
      await ApiService.get("prep-app/seo/listPage").then(function (response) {
        if (response.status === 200) {
          vm.pages = response.data;
        }
      });
    },
    openDiaLogInfo() {
      this.type_form = "add";
      this.type_input = null;
      this.item_input = null;
      this.page_input = null;
      this.title_input = "";
      this.description_input = "";
      this.slug_input = "";
      this.keyword_input = [];
      this.diaLogInfo = true;
    },
    validateForm() {
      let flat = true;
      if (this.title_input === "" || this.title_input == null) {
        this.$toasted.error("Hãy nhập tiêu đề !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.keyword_input.length <= 0) {
        this.$toasted.error("Hãy nhập ít nhất 1 keyword !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.type_input == null) {
        this.$toasted.error("Hãy chọn type !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.type_input === 1) {
        if (this.page_input == null) {
          this.$toasted.error("Hãy chọn page !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      } else if (this.type_input === 2 || this.type_input === 3) {
        if (this.item_input == null) {
          this.$toasted.error("Hãy chọn item !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
        if (this.slug_input === "" || this.slug_input === null) {
          this.$toasted.error("Hãy nhập slug !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      if (this.type_input === 5 && this.item_input == null) {
        this.$toasted.error("Hãy chọn benefit !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditInfo(type) {
      let vm = this;
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let data = {
        type: this.type_input,
        title: this.title_input,
        keywords: this.keyword_input,
      };
      if (this.description_input) data.description = this.description_input;
      if (this.type_input === 1) data.page = this.page_input;
      if (this.type_input === 2 || this.type_input === 3 || this.type_input === 4) data.slug = this.slug_input;
      if (this.type_input === 2 || this.type_input === 3 || this.type_input === 4 | this.type_input === 5) data.item_id = this.item_input;
      vm.is_call_api = true;
      if (type === 1) {
        await ApiService.post("prep-app/seo", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllSEOInfo();
              vm.$toasted.success("Tạo thành công !", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              vm.diaLogInfo = false;
              vm.is_call_api = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              vm.is_call_api = false;
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put("prep-app/seo/" + this.SEO_info_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllSEOInfo();
              vm.$toasted.success("Sửa thành công !", {theme: "toasted-primary", position: "top-right", duration: 4000,});
              vm.is_call_api = false;
              vm.diaLogInfo = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error = error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
              vm.is_call_api = false;
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editInfo(index) {
      this.type_form = "update";
      let data = this.SEO_informations[index];
      this.SEO_info_id = data.id;
      this.type_input = data.type;
      this.item_input = data.item ? data.item.id : null;
      this.page_input = data.page;
      this.title_input = data.title;
      this.description_input = data.description == null ? "" : data.description;
      this.slug_input =
        data.type === 2 || data.type === 3 || data.type === 4
          ? data.item.slug
          : null;
      this.keyword_input = data.keywords;
      this.diaLogInfo = true;
    },
    btnDeleteInfo(SEO_info_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/seo/" + SEO_info_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllSEOInfo();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    changeTypeSEO() {
      this.page_input = null;
      this.item_input = null;
      this.slug_input = "";
    },
    chooseProgramme(e) {
      let vm = this;
      this.programmes.forEach((programme) => {
        if (programme.id === e) {
          vm.slug_input = programme.slug;
        }
      });
    },
    chooseProductCategory(e) {
      let vm = this;
      this.product_categories.forEach((product) => {
        if (product.id === e) {
          vm.slug_input = product.slug;
        }
      });
    },
    chooseProductSubCategory(e) {
      let vm = this;
      this.product_sub_categories.forEach((product) => {
        if (product.id === e) {
          vm.slug_input = product.slug;
        }
      });
    },
    addItemKeyword() {
      if (this.item_keyword_input === "" || this.item_keyword_input == null) {
        this.$toasted.error("Không được bỏ trống !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      if (this.index_item_keyword == null) {
        this.keyword_input.push(this.item_keyword_input);
      } else {
        this.keyword_input[this.index_item_keyword] = this.item_keyword_input;
      }
      this.index_item_keyword = null;
      this.item_keyword_input = "";
    },
    editItemKeyword(index) {
      this.index_item_keyword = index;
      this.item_keyword_input = this.keyword_input[index];
    },
    deleteItemKeyword(index) {
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.keyword_input.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
